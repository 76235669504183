<template>
    <div class="container-fluid full-section">
        <div class="container">
            <div class="row">
                <div class="col-md-8">
                    <h2>404</h2>
                    <h4>извините, страница не найдена <br>Куда пойдем дальше?</h4>
                    <ul class="left-menu" v-if="isCurrentUserDealer">
                        <li>
                            <router-link :to="{name: 'Profile'}"><u>Личный кабинет</u></router-link>
                        </li>
                        <li>
                            <router-link :to="{name: 'Home'}"><u>Главная</u></router-link>
                        </li>
                        <li>
                            <router-link to="/contacts"><u>Обратная связь</u></router-link>
                        </li>
                    </ul>
                    <ul class="left-menu" v-if="isCurrentUserStructuralSubdivisionUser">
                        <li>
                            <router-link to="/structural-subdivision-user-cabinet/profile"><u>Личный кабинет</u>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/work-rules"><u>Правила работы</u></router-link>
                        </li>
                        <li>
                            <router-link to="/contacts"><u>Обратная связь</u></router-link>
                        </li>
                    </ul>
                </div>
                <div class="col-md-3 col-md-offset-1">
                    <div><img class="img_" src="../../assets/images/container.png"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NotFound",
    metaInfo() {
        return {
            title: `Данной страницы не существует - ЛУКМАРКЕТ`,
        }
    },
}
</script>

<style scoped>
h2 {
    color: #ff103e;
    font-size: 85px;
    margin: 60px 0 0 60px;
    /*font-weight:bold;*/
}

h4 {
    text-transform: uppercase;
    margin: 30px 0 0 60px;
}

ul.left-menu {
    list-style: none;
    padding-left: 0;
    margin: 30px 0 0 60px;
}

ul.left-menu a:link,
ul.left-menu a:visited,
ul.left-menu a {
    color: #444;
}

ul.left-menu li a {
    display: block;
    margin-left: 0em;
    padding: 1em 0;
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
    font-weight: bold;
}

.img_ {
    margin: 120px 0 0 10px;
    width: 258px;
    height: 395px;
}
</style>
